<template>
    <div class="table-container">
        <table class="table-border" v-if="dashboardData">
            <thead>
                <tr>
                    <th class="th-special-0"></th>
                    <th class="table-column-title th-special-">Всего продаж</th>
                    <th class="table-column-title th-special-2">Всего заказов</th>
                    <th class="table-column-title th-special-3">Средний чек</th>
                    <th class="table-column-title th-special-5">Среднее количество товаров в заказе</th>
                </tr>
            </thead>
            <tbody>
                <!-- <h2>Daily Revenues</h2>
      <tr v-for="(day, index) in dashboardData.dailyRevenues" :key="index">
        <td>{{ new Date(day.date).toLocaleDateString() }}</td>
        <td>{{ day.totalRevenue }}</td>
        <td>{{ day.deliveryRevenue }}</td>
        <td>{{ day.selfPickUpRevenue }}</td>
        <td>{{ day.inHouseRevenue }}</td>
      </tr> -->
                <tr v-for="(channel, index) in sortedChannels" :key="index">
                    <td class="table-title">{{ getPickUpTypeTranslation(channel.pickUpType) }}</td>
                    <td class="price-table">
                        <div class="price-container-1">
                            <div class="pr-20">
                                <p class="price-table mb-0">{{ channel.totalRevenue.amount.toLocaleString('ru-RU') }} ₸
                                </p>
                            </div>
                            <div>
                                <span class="percent-status" v-if="channel.totalRevenue.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ channel.totalRevenue.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="channel.totalRevenue.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ channel.totalRevenue.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="channel.totalRevenue.percent < 0">
                                    <span class="percent-text-red">{{ channel.totalRevenue.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="channel.totalRevenue.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-2">


                            <div class="pr-20">
                                <p class="price-table mb-0">{{ channel.ordersAmount.amount }}</p>
                            </div>
                            <div class="margin-50">
                                <span class="percent-status" v-if="channel.ordersAmount.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ channel.ordersAmount.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="channel.ordersAmount.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ channel.ordersAmount.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="channel.ordersAmount.percent < 0">
                                    <span class="percent-text-red">{{ channel.ordersAmount.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="channel.ordersAmount.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-3">


                            <div class="pr-20">
                                <p class="price-table mb-0">{{
                                    channel.averageOrderRevenue.amount.toLocaleString('ru-RU') }} ₸</p>
                            </div>
                            <div>
                                <span class="percent-status" v-if="channel.averageOrderRevenue.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ channel.averageOrderRevenue.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="channel.averageOrderRevenue.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ channel.averageOrderRevenue.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding"
                                    v-if="channel.averageOrderRevenue.percent < 0">
                                    <span class="percent-text-red">{{ channel.averageOrderRevenue.percent }}%</span>
                                    <span class="arrow-color-bot"
                                        v-if="channel.averageOrderRevenue.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-5">


                            <div class="pr-20">
                                <p class="price-table mb-0">{{ channel.averageOrderSize.amount }}</p>
                            </div>
                            <div class="margin-35">
                                <span class="percent-status" v-if="channel.averageOrderSize.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ channel.averageOrderSize.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="channel.averageOrderSize.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ channel.averageOrderSize.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding"
                                    v-if="channel.averageOrderSize.percent < 0">
                                    <span class="percent-text-red">{{ channel.averageOrderSize.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="channel.averageOrderSize.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>


                </tr>
                <tr class="total-big-container">
                    <td class="table-title-total">Итого</td>
                    <td class="price-table">
                        <div class="price-container-1">
                            <div class="pr-20">
                                <p class="price-table-total mb-0">{{
                                    totalRow.totalRevenue.amount.toLocaleString('ru-RU') }} ₸</p>
                            </div>
                            <div class="">
                                <span class="percent-status" v-if="totalRow.totalRevenue.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-white">{{ totalRow.totalRevenue.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="totalRow.totalRevenue.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-white">{{ totalRow.totalRevenue.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="totalRow.totalRevenue.percent < 0">
                                    <span class="percent-text-white">{{ totalRow.totalRevenue.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="totalRow.totalRevenue.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-2">
                            <div class="pr-20">
                                <p class="price-table-total mb-0">{{ totalRow.ordersAmount.amount }}</p>
                            </div>
                            <div class="margin-50">
                                <span class="percent-status" v-if="totalRow.ordersAmount.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-white">{{ totalRow.ordersAmount.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="totalRow.ordersAmount.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-white">{{ totalRow.ordersAmount.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="totalRow.ordersAmount.percent < 0">
                                    <span class="percent-text-white">{{ totalRow.ordersAmount.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="totalRow.ordersAmount.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-3">
                            <div class="pr-20">
                                <p class="price-table-total mb-0">{{
                                    totalRow.averageOrderRevenue.amount.toLocaleString('ru-RU') }} ₸</p>
                            </div>
                            <div>
                                <span class="percent-status" v-if="totalRow.averageOrderRevenue.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-white">{{ totalRow.averageOrderRevenue.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="totalRow.averageOrderRevenue.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-white">{{ totalRow.averageOrderRevenue.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding"
                                    v-if="totalRow.averageOrderRevenue.percent < 0">
                                    <span class="percent-text-white">{{ totalRow.averageOrderRevenue.percent }}%</span>
                                    <span class="arrow-color-bot"
                                        v-if="totalRow.averageOrderRevenue.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="price-table">
                        <div class="price-container-5">
                            <div class="pr-20">
                                <p class="price-table-total mb-0">{{ totalRow.averageOrderSize.amount.toFixed(2) }}</p>
                            </div>
                            <div class="margin-35">
                                <span class="percent-status" v-if="totalRow.averageOrderSize.percent === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-white">{{ totalRow.averageOrderSize.percent }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="totalRow.averageOrderSize.percent > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-white">{{ totalRow.averageOrderSize.percent }}%</span>
                                </span>
                                <span class="percent-status percent-padding"
                                    v-if="totalRow.averageOrderSize.percent < 0">
                                    <span class="percent-text-white">{{ totalRow.averageOrderSize.percent }}%</span>
                                    <span class="arrow-color-bot" v-if="totalRow.averageOrderSize.percent < 0">▼</span>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "TableChart",
    props: {
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
    },
    data() {
        return {

        }

    },
    methods: {
        getPickUpTypeTranslation(pickUpType) {
            switch (pickUpType) {
                case 'Delivery':
                    return 'Доставка';
                case 'SelfPickUp':
                    return 'Навынос';
                case 'InHouse':
                    return 'В зале';
                default:
                    return "Не указан";  // Если есть другие значения, показывать их как есть
            }
        },
    },
    computed: {
        totalRow() {
            // Получаем строку, где pickUpType равен null, это и есть итоговая строка
            return this.dashboardData.totalChannelRevenues.find(item => item.pickUpType === null);
        },

        sortedChannels() {

            const pickUpTypeOrder = {
                'InHouse': 1,    // В зале
                'Delivery': 2,   // Доставка
                'SelfPickUp': 3  // Навынос
            };

            return this.dashboardData.totalChannelRevenues
                .filter(channel => channel.pickUpType !== null) // Убираем элементы с null
                .sort((a, b) => pickUpTypeOrder[a.pickUpType] - pickUpTypeOrder[b.pickUpType]);
        }

    },
    mounted() {
        console.log('Received dashboardData in TableChart:', this.dashboardData); // Лог данных
    }
}

</script>
<style scoped>
.pr-20 {
    padding-right: 1.0416vw;
}

.percent-text-gray {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

/* .th-special-0 {
    width: 100px;

}

.th-special-1 {
    width: 142px;

}

.th-special-2 {
    width: 8vw
}

.th-special-3 {
    width: 11vw;

}

.th-special-4 {
    width: 212px;

}

.th-special-5 {
    width: 11.145vw;

    line-height: 1.1;
} */

.percent-text-white {
    color: white;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}


.price-table-total {
    font-family: Inter;
    font-weight: 700;
    font-size: 0.83vw;
    color: white;
}

.total-big-container {
    background-color: #323c4d;
}

.table-title-total {
    font-family: Inter;
    font-weight: 700;
    font-size: 0.83vw;
    color: white;
}


.table-container {
    padding: 10px 0 0 0;
    background: #fff;
    border-radius: 5px;
    width: max-content;
    max-width: 49.270vw;
    width: 100%;
    height: 100%;
}

.table-border {
    background-color: white;
}

.price-container-1 {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: flex-end;
}

/* .margin-50 {
    margin-right: 50px
} */

.price-container-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* margin-right: 22px; */
}

.price-container-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* margin-right: 22px; */
}

.price-container-5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

/* .margin-35 {
    margin-right: 100px;
} */


.price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
}

.neutral-arrows {
    color: #98a2b2;
    padding-bottom: 2px;
    padding-top: 2px;
}

.percent-padding {
    padding-top: 13px;
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    margin-top: -11px;
    line-height: 1;
    width: 37px;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    line-height: 1;
    width: 37px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.price {
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: rgb(50, 60, 77);
}

.price-table {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    color: #323c4d;
    width: max-content;
}

.table-column-title {
    font-family: Inter;
    font-weight: 700;
    font-size: 0.78125vw;
    color: #323c4d;
    text-align: center;
}

.table-title {
    font-family: Inter;
    font-weight: 700;
    font-size: 0.83vw;
    color: #323c4d;
}

table {
    /* max-width: 946px; */
    border-collapse: collapse;
    /* margin: 20px 0; */
    font-size: 18px;
    text-align: center;
    width: 100%;
    /* max-width: 946px; */
    /* width: max-content; */
}

th {
    padding: 0 0.625vw;
    border-bottom: 1px solid #D4D4D4;
    text-align: start;
}

tr {
    height: 53px;
}

td {
    padding: 0vw 0.83vw;
    border-bottom: 1px solid #D4D4D4;
    text-align: start;
}


.total-row {
    background-color: #374151;
    color: #fff;
}

.green {
    color: green;
}

.red {
    color: red;
}

.grey {
    color: grey;
}

.arrow {
    font-size: 12px;
    margin-left: 5px;
}

.arrow-up {
    color: green;
}

.arrow-down {
    color: red;
}

.arrow-right {
    color: grey;
}

@media screen and (max-width:1000px) {
    .table-title-total {
        font-size: 12px;
    }

    .price-table {

        font-size: 10px;

    }

    .price-table-total {

        font-size: 10px;
    }

    .table-container {
        padding: 10px 0 0 0;
        display: flex;
        margin: 0 auto;
        background: #fff;
        border-radius: 5px;
        /* width: max-content; */
        max-width: 90vw;
        width: 90%;
        height: 100%;
    }

    .table-column-title {

        font-size: 10px;

    }

    .table-title {
        font-size: 12px;

    }

    .percent-text-green {

        font-size: 8px;
    }

    .percent-text-red {

        font-size: 8px
    }

    .percent-text-white {

        font-size: 8px
    }

}


@media screen and (max-width:700px) {



    .table-title-total {
        font-size: 12px;
    }

    .price-table {

        font-size: 10px;

    }

    .price-table-total {

        font-size: 10px;
    }

    .table-container {
        padding: 10px 0 0 0;
        display: flex;
        margin: 0 auto;
        background: #fff;
        border-radius: 5px;
        /* width: max-content; */
        max-width: 90vw;
        width: 90%;
        height: 100%;
    }

    .table-column-title {

        font-size: 12px;

    }

    .table-title {
        font-size: 12px;

    }

    .percent-text-green {

        font-size: 8px;
    }

    .percent-text-red {

        font-size: 8px
    }

    .percent-text-white {

        font-size: 8px
    }

}


@media screen and (max-width:500px) {

    .table-border {
        margin-left: -7.5px;
    }

    .table-title-total {
        font-size: 9px;
    }


    .price-table {

        font-size: 8px;

    }

    .price-table-total {

        font-size: 8px;
    }

    .percent-text-green {

        font-size: 7px;
    }

    .percent-text-red {

        font-size: 7px
    }

    .percent-text-white {

        font-size: 7px
    }


    .table-column-title {

        font-size: 8px;

    }

    .price-table-total {

        font-size: 8px;
    }


    .table-title {
        font-size: 7.5px;

    }
}

@media screen and (max-width:340px) {
    .table-container {
        margin: 0;
    }
}
</style>
