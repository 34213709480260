<template>
    <div class="charts-container">
        <div class="doughnut-container">
            <Doughnut :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                :width="width" :height="height" />
            <div class="total-container-doughnut">
                <p class="total-price-doughnut">{{ centerText }}</p>
            </div>

            <div v-if="revenuePercent === 0" class="cost-container-gray">
                <span class="percent-status" v-if="revenuePercent === 0">
                    <span class="neutral-arrows">▲</span>
                    <span class="percent-text-gray">{{ revenuePercent }}%</span>
                    <span class="neutral-arrows neutral-arrows-special-2">▼</span>
                </span>

            </div>
            <div v-if="revenuePercent > 0" class="cost-container-green">
                <span class="percent-status-green" v-if="revenuePercent > 0">
                    <span class="arrow-color-top">▲</span>
                    <span class="percent-text-green">{{ revenuePercent }}%</span>
                </span>

            </div>
            <div v-if="revenuePercent < 0" class="cost-container-red">

                <span class="percent-status percent-status-red" v-if="revenuePercent < 0">
                    <span class="percent-text-red">{{ revenuePercent }}%</span>
                    <span class="arrow-color-bot" v-if="revenuePercent < 0">▼</span>
                </span>

            </div>


        </div>
        <div class="bars-main-container">
            <HorizontalChart :dashboardData="dashboardData" />
        </div>
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import HorizontalChart from './HorizontalChart.vue'

// Регистрация плагинов
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

export default {
    name: 'DoughnutChart',
    components: {
        Doughnut,
        HorizontalChart
    },
    props: {
        chartId: {
            type: String,
            default: 'doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 185
        },
        height: {
            type: Number,
            default: 185
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Object,
            default: () => ({})
        },
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
    },

    data() {

        return {
            centerText: "",
            revenuePercent: "",
            hozironChartData: {},
            // chartData: {
            //     labels: ['В зале', 'Доставка', 'Навынос'],
            //     datasets: [
            //         {
            //             backgroundColor: ['#13414E', '#006D5A', '#14BF91'],
            //             data: [60, 30, 10],
            //             borderWidth: 0,

            //         }
            //     ]
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                gap: 0,
                cutout: '55%',
                centerText: this.centerText,
                plugins: {
                    legend: {
                        display: false // Отключаем легенду
                    },
                    datalabels: {
                        formatter: (value, context) => {
                            // Если значение равно 0, не отображаем процент
                            console.log(value);

                            if (value == 0) {
                                return null;
                            }
                            const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                            const percentage = value + '%';
                            return percentage;
                        },
                        color: '#fff', // Цвет текста
                        font: {
                            family: "Inter",
                            weight: '700',
                            size: 13
                        }
                    },

                }
            }
        }

    },
    computed: {
        chartData() {
            // Проверяем наличие данных в пропсе
            const revenues = this.dashboardData.totalChannelRevenues || [];
            const labels = revenues.map((item, index) => {
                if (index !== 3) {
                    return this.formatPickUpType(item.pickUpType);
                }
                return null;
            }).filter(label => label !== null);  // Удаляем null значения

            // Вычисляем общую сумму выручки для расчета процентов
            const totalRevenueSum = revenues.reduce((sum, channel, index) => {
                if (channel.pickUpType !== null) {  // Пропускаем 4-й элемент
                    return sum + channel.totalRevenue.amount;
                }
                return sum;
            }, 0);

            // Массив для хранения процентов выручки
            const percentData = revenues.map((channel, index) => {
                this.centerText = `${totalRevenueSum.toLocaleString('ru-RU')} ₸`;


                if (channel.pickUpType !== null && index !== 3) {  // Пропускаем 4-й элемент
                    const percentOfTotal = (channel.totalRevenue.amount / totalRevenueSum) * 100;
                    console.log(`Тип доставки: ${channel.pickUpType} составляет ${percentOfTotal.toFixed(0)}% от общей выручки.`);
                    const percentOfChannel = {
                        [channel.pickUpType]: percentOfTotal.toFixed(0),
                    };
                    console.log(percentOfChannel);
                    return percentOfTotal.toFixed(0);  // Сохраняем процент
                }
                else {
                    this.revenuePercent = channel.totalRevenue.percent;
                }
                return null;  // Пропускаем 4-й элемент
            }).filter(percent => percent !== null);

            console.log(labels);
            console.log(percentData);
            const typeColors = {
                "Доставка": "#006D5A",
                "Навынос": "#14BF91",
                "В зале": "#13414E"
            };
            const backgroundColor = labels.map(label => typeColors[label]);
            return {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: backgroundColor,
                        data: percentData,
                        borderWidth: 0
                    }
                ]
            };
        }
    },
    methods: {
        formatPickUpType(pickUpType) {
            switch (pickUpType) {
                case 'Delivery':
                    return 'Доставка';
                case 'SelfPickUp':
                    return 'Навынос';
                case 'InHouse':
                    return 'В зале';
                default:
                    return pickUpType;
            }
        }
    },
    mounted() {

        // var centerTextPlugin = {
        //     id: 'centerTextPlugin',
        //     beforeDraw: (chart) => {
        //         var ctx = chart.ctx;
        //         var width = chart.width;
        //         var height = chart.height;
        //         ctx.restore();

        //         ctx.font = '700 13px "Inter", sans-serif'; // Жирный, 13px, шрифт Inter
        //         ctx.fillStyle = '#000'; // Цвет текста черный
        //         ctx.textBaseline = "middle"; // Вертикальное выравнивание по середине


        //         var text = this.centerText;

        //         var amount = text ? Number(text).toLocaleString('ru-RU') + ' ₸' : ''; // Форматируем число и добавляем символ валюты

        //         var textX = Math.round((width - ctx.measureText(amount).width) / 2);
        //         var textY = height / 2;

        //         ctx.fillText(amount, textX, textY);
        //         ctx.save();

        //     }

        // };
        //ChartJS.register(centerTextPlugin);
        // this.$refs.chartInstance.update();

    },


}
</script>


<style scoped>
.charts-container {
    display: flex;
}

.total-price-doughnut {
    font-family: Inter;
    font-weight: 700;
    font-size: 0.625vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.total-container-doughnut {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -80%);
    text-align: center;
}

.bars-main-container {
    margin-left: 67px;
    width: 100%;
    max-width: 327px;
}

.percent-status-gray {
    margin-top: 5px;
}

.percent-status-red {
    margin-top: 5px;
}

.percent-text-gray {

    /* color: #c1c1c1;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px; */
    padding-top: 3px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
}

.neutral-arrows-special {
    margin-top: -8px
}

.neutral-arrows-special-2 {
    margin-top: -8px
}

.neutral-arrows {
    color: #98a2b2;
    /* padding-bottom: 2px;
    padding-top: 2px; */
    height: 13px;
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    margin-top: 5px;
    line-height: 1;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    /* line-height: 1; */
}

.container {
    display: flex;
}

.doughnut-container {
    position: relative;
    z-index: 1;
}

.cost-container-red {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.cost-container-green {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;
}

.cost-container-gray {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.percent-in-doughnut {
    line-height: 0px;
    font-size: 18px;
    color: red;

}

.cost-in-doughnut {

    font-family: Inter;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.73px;
    text-align: left;
    margin-bottom: 0px;
    z-index: 0;
    /* word-wrap: break-word; */
    /* Разрешить перенос слов */
    /* white-space: normal; */
    /* Позволяет тексту переноситься */
    /* max-width: 100%; */
    /* Ограничение ширины текста */
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
    margin-top: -7px;
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

/* Horizontal price  */

.bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    height: 100%;
    gap: 30px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.horizontal-bar {
    background: #F0F0F0;
    width: 330px;
    height: 10px;
}

.horiontal-bar-fill {
    height: 10px;
    animation: horizontal-fill 1s;
    transition: all 1s ease-in-out;
}

@keyframes horizontal-fill {
    from {
        width: 0%;
    }

    to {
        width: inherit;
    }
}

.horizontal-bar-container {
    list-style-type: none;
}

.name-container {
    display: flex;
    align-items: center;
}

.name-container p {
    line-height: 0px;
    margin-top: 15px;
    margin-left: 10px;
}

.name-square {
    width: 10px;
    height: 10px;
    border-radius: 2px;
}


@media screen and (max-width:1000px) {
    #doughnut-chart {
        max-width: 100px;
    }

    .doughnut-container {
        width: 150px;
    }

    .bars-main-container {
        margin-left: 35px;
    }

    .total-price-doughnut {
        font-size: 10px;

    }

    .percent-text-gray {
        font-size: 8px;
    }

    .percent-text-red {
        font-size: 8px;
    }

    .percent-text-green {
        font-size: 8px;
    }

}



@media screen and (max-width:860px) {
    .bars-main-container {
        margin-left: 25px;
    }

    #doughnut-chart {
        max-width: 100px;
    }

    .doughnut-container {
        width: 120px;
    }

}

@media screen and (max-width:700px) {

    .doughnut-container {
        width: 100%;
    }

    .total-price-doughnut {
        font-size: 10px;

    }

    .percent-text-gray {
        font-size: 8px;
    }

    .percent-text-red {
        font-size: 8px;
    }

    .percent-text-green {
        font-size: 8px;
    }

}

@media screen and (max-width:500px) {
    .bars-main-container {
        margin-left: 0;
        width: 100%;
        max-width: 327px;
        margin: 20px auto 0;
    }

    .charts-container {
        flex-direction: column;
    }

}
</style>