<template>
  <Bar class="custom-class" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 1200
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    plugins: {
      type: Object,
      default: () => ({})
    },
    dashboardData: {
      type: Object,  // Мы ожидаем объект
      default: () => ({})
    },
  },
  data() {
    return {
      // chartData: {
      //   labels: [
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09",
      //     "7.09",
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09",
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09"
      //   ],
      //   datasets: [
      //     {
      //       label: 'В зале',
      //       backgroundColor: '#13414E',
      //       data: [25000, 28000, 15000, 55000, 37000, 40000, 20000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 40000]
      //     },
      //     {
      //       label: 'Доставка',
      //       backgroundColor: '#006D5A',
      //       data: [65000, 18000, 45000, 25000, 37000, 60000, 57000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 40000]
      //     },
      //     {
      //       label: 'На вынос',
      //       backgroundColor: '#14BF91',
      //       data: [15000, 48000, 35000, 75000, 47000, 20000, 17000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 4000]
      //     }
      //   ]
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            labels: {
              font: {
                family: 'Inter', // Шрифт для легенды
                size: 15, // Размер шрифта для легенды
              },
              color: '#323c4d' // Цвет шрифта для легенды
            }
          },
          tooltip: {
            titleFont: {
              family: 'Inter', // Шрифт для заголовка подсказок
              size: 14, // Размер шрифта заголовка подсказок
            },
            titleColor: '#FFFFFF', // Цвет шрифта заголовка подсказок
            bodyFont: {
              family: 'Inter', // Шрифт для текста подсказок
              size: 14, // Размер шрифта текста подсказок
            },
            callbacks: {
              label: function (tooltipItem) {
                // Название серии данных
                let datasetLabel = tooltipItem.dataset.label || '';
                // Значение с форматированием
                let value = tooltipItem.raw.toLocaleString('ru-RU');
                return `${datasetLabel}: ${value}`;
              }
            },
            bodyColor: '#FFFFFF', // Цвет шрифта текста подсказок
          }
        },
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.3, // Процент от ширины доступной категории (0.0 до 1.0)
            barThickness: 100,
            ticks: {
              // callback: function(value) {
              //   return value.replace(/\s+/g, ' '); // Удаляет пробелы, если они не поддерживаются
              // },
              rotation: 0,
              align: 'center',
              font: {
                family: 'Inter', // Шрифт для оси X
                size: 12, // Размер шрифта для оси X
              },
              color: '#000000' // Цвет шрифта для оси X
            }
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: 'Inter', // Шрифт для оси Y
                size: 12, // Размер шрифта для оси Y
              },
              color: '#000000' // Цвет шрифта для оси Y
            }
          }
        }
      }
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Форматируем дату в нужный формат (например, "дд.мм")
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    }
  },
  computed: {
    chartData() {
      // const { dailyRevenues } = this.dashboardData;
      const dailyRevenues = this.dashboardData.dailyRevenues || [];

      // Получаем метки и данные для каждого дня
      const labels = dailyRevenues.map(item => {
        const date = new Date(item.date);
        const dayOfWeek = (date.toLocaleDateString('ru-RU', { weekday: 'short' }));
        const formattedDate = this.formatDate(item.date);
        //       if (dayOfWeek === 'сб' || dayOfWeek === 'вс') {
        //   return [`🔴 ${dayOfWeek}`, formattedDate]; // Пример: "🔴 Сб\n01.09"
        // } else {
        return [dayOfWeek, formattedDate]
      });
      const inHouseData = dailyRevenues.map(item => item.inHouseRevenue);
      const deliveryData = dailyRevenues.map(item => item.deliveryRevenue);
      const selfPickUpData = dailyRevenues.map(item => item.selfPickUpRevenue);

      return {
        labels: labels,

        datasets: [
          {
            label: 'В зале',
            backgroundColor: '#13414E',
            data: inHouseData,
            // barThickness: 66,
            // categoryPercentage: 1,


          },
          {
            label: 'Доставка',
            backgroundColor: '#006D5A',
            data: deliveryData,
            font: 'Inter',
            // barThickness: 66,
            // categoryPercentage: 1,
          },
          {
            label: 'Навынос',
            backgroundColor: '#14BF91',
            data: selfPickUpData,
            font: 'Inter',
            // barThickness: 66,
            // categoryPercentage: 1,
          }
        ]
      };
    }
  }
}
</script>


<style scoped>
.custom-class {
  width: 100%;
  /* 100% ширины родительского контейнера */
  height: 352px;
  /* 50% от высоты окна просмотра */
  position: relative;
  /* Для корректного рендеринга Chart.js */
}
</style>